import React from 'react';
import { Table, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Button from 'antd/es/button';
import DataTable from '../../../components/data-display/table/DataTable';
import {TextCapitalize,usaFormatNumber } from '../../../utils/helper'; 
import edit from "../../../images/new/edit_icon_blue.svg"
import Register from "./addLocation"
const List = (props) => {
  const tableColumns=[
    {name: "LOCATION NAME",label: "Yard Name",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
    {name: "LOCATION ADDRESS",label: "Yard Location",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
    // {name: "LOCATION PHONE",label: "Phone No.",options: {filter: true,sort: true,customBodyRender: value =>usaFormatNumber(value)}},
    {name: "",label: "",options: {filter: false,sort: false,}},
   
  ]
  const columns = [
    {
      title: 'LOCATION NAME',
      dataIndex: 'name',
      width: 200,
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'LOCATION ADDRESS',
      dataIndex: 'address',
      width: 200,
      sorter: {
        compare: (a, b) => {
          a = a.address.toLowerCase();
          b = b.address.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_, elm) => (
        <div className="text-right">
          <Tooltip title="Edit">
            <Button
              type="link"
              className="mr-2"
              icon={<EditOutlined />}
              onClick={() => {
                props.EditModeOn(props.yardList[elm.key]);
              }}
              size="small"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const data = [];
  const tableData=[]
  props.yardList &&
    props.yardList.map((item, index) => {
      data.push({
        key: index,
        name: item.name,
        address: item.address,
        // phone:item.phone
      });
      tableData.push([item.name,item.address,
        // item.phone,
        <Register data={item} 
          Button={ <div style={{cursor:'pointer'}}>
                   <img src={edit} alt='icon'/>
                   <span style={{color:'#38B6FF',marginLeft:'7px'}}>Edit</span> 
                  </div>}
          title="EDIT YARD LOCATION"
          custom={true}
          className="wash_po_add_edit_btn"
          />
      ])
    });
  // for (let i = 0; i < 100; i++) {
  //     data.push({
  //         key: i,
  //         name: `Edward King ${i}`,
  //         address: `London, Park Lane no. ${i}`,
  //     });
  // }

  return (
    <div>
      <DataTable
      loader={props.loading}
      header={tableColumns}
      data={tableData}
      rowKey="id" />
    </div>
  );
};
export default List;
